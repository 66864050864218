import React, {useEffect, useState} from "react";
import backgroundDes from '../assets/img/backgroundDes.png';
import backgroundMob from '../assets/img/backgroundMob.png';
import styles from './Content.module.scss';
import Logo from "./Logo/Logo";
import LeftNavBar from "./NavBars/LeftNavBar";
import RightNavBar from "./NavBars/RightNavBar";
import MainButton from "./MainButton/MainButton";
import Popups from "./Popups/Popups";
import {ethers} from "ethers";
import {useAppDispatch} from "../hooks/redux";
import {
    setActivePopup, setAffiliate,
    setCoinsBalance,
    setCookiesBalance, setMyInvested, setMyWithdrawn, setTotalPlayers,
    setWalletAddress,
    setWalletBalance
} from "../store/user-slice";
import Balances from "./Balances/Balances";
import {usePopup} from "../hooks/useGlobalFunction";
import {
    addMaticNetwork, addPartner,
    getContract,
    getCookies, getPartner,
    getProvider,
    sendConnect
} from "../api/ethereum";
import {useSelector} from "react-redux";
import {getWalletAddress} from "../store/selectors";
import Animals from "./Animals/Animals";
import AllAnimals from "./Animals/AllAnimals";
import UserBalance from "./Popups/UserBalance/UserBalance";

const Content = () => {
    const dispatch = useAppDispatch();
    const {setPopup} = usePopup();
    const isAuth = useSelector(getWalletAddress);
    const [connected, setConnected] = useState(false)
    const [provider, setProvider] = useState(null);
    const [contract, setContract] = useState(null);

    const currentUrl = window.location;
    if (currentUrl.pathname.length > 5) {
        getPartner().then(r => {
            if (!r) {
                const partnerId = currentUrl.pathname.slice(1);
                addPartner(partnerId);
            }
        })
    }

    // useEffect(() => {
    //     setPopup('start').then(() => {
    //         dispatch(setActivePopup('actionEvent'));
    //     });
    // }, [connected])

    useEffect(() => {
        getCurrentWalletConnection();
        addWalletListener();
    })

    useEffect(() => {
        if (isAuth) {
            getProvider().then(r => {
                setProvider(r);
            })
            setConnected(true);
        }
    }, [isAuth]);

    if (window.ethereum) {
        window.ethereum.on('networkChanged', (e) => {
            if (e !== '97') {
                setPopup('start').then(() => {
                    dispatch(setActivePopup('failed'));
                    setTimeout(() => {
                        addMaticNetwork().then(() => console.log('change'))
                    }, 5000)
                });
            }
            getProvider().then(r => {
                setProvider(r);
            })
        })

        window.ethereum.on('disconnect', () => {
            setConnected(false);
            getProvider().then(r => {
                setProvider(r);
            })

        })

        window.ethereum.on('accountsChanged', () => {
            setConnected(false);
            getProvider().then(r => {
                setProvider(r);
            })
        })
    }

    useEffect(() => {
        if (isAuth && provider) {
            getContract(provider).then(r => {
                setContract(r);
            });
        }
    }, [isAuth, provider]);

    useEffect(() => {
        if (contract) {
            getCookies(contract, isAuth).then(r => {
                const cookies = r[0].toString();
                const coins = r[1].toString();
                const invested = r[8].toString();
                const withdrawn = r[9].toString();
                const affiliate = {
                    players: r[5].toString(),
                    coins: r[6].toString(),
                    cookies: r[7].toString(),
                }
                dispatch(setCookiesBalance(cookies));
                dispatch(setCoinsBalance(coins));
                dispatch(setAffiliate(affiliate));
                dispatch(setMyInvested(invested));
                dispatch(setMyWithdrawn(withdrawn));
            });
            contract.totalPlayers().then((result) => {
                const players = result.toString();
                dispatch(setTotalPlayers(players))
            })

        }
    }, [contract]);


    const getWalletBalance = (accountAddress) => {
        if (typeof window != "undefined" && typeof window.ethereum != "undefined") {
            try {
                window.ethereum.request({method: "eth_getBalance", params: [String(accountAddress), "latest"]})
                    .then((result) => {
                        dispatch(setWalletBalance(ethers.formatEther(result)))
                    })
            } catch (error) {
                console.log(error.code);
            }
        } else {
            console.log('Please install MetaMask');
        }
    }

    const ConnectWallet = () => {
        if (typeof window != "undefined" && typeof window.ethereum != "undefined") {
            addMaticNetwork().then(() => {
                sendConnect().then((result) => {
                    if (result === false) {
                        setPopup('start').then(() => dispatch(setActivePopup('failed')));
                    } else {
                        dispatch(setWalletAddress(result));
                        getWalletBalance(result);
                        setPopup('stop').then(() => dispatch(setActivePopup(null)));
                        setConnected(true);
                    }
                })
            })
        } else {
            console.log('Please install MetaMask');
        }
    }

    const getCurrentWalletConnection = () => {
        if (typeof window != "undefined" && typeof window.ethereum != "undefined") {
            try {
                window.ethereum.request({method: "eth_accounts"})
                    .then((accounts) => {
                        if (accounts.length > 0) {
                            dispatch(setWalletAddress(accounts[0]))
                            getWalletBalance(accounts[0])
                        } else {
                            console.log("Connect to metaMask using the Connect button")
                        }
                    })
            } catch (error) {
                console.log(error.code);
            }
        } else {
            console.log('Please install MetaMask');
        }
    }

    const addWalletListener = () => {
        if (typeof window != "undefined" && typeof window.ethereum != "undefined") {
            window.ethereum.on("accountChanged", (accounts) => {
                dispatch(setWalletAddress(accounts[0]))
                getWalletBalance(accounts[0])
            })
        } else {
            dispatch(setWalletBalance(''))
            console.log('Please install MetaMask');
        }
    }

    return (
        <div
            style={window.innerWidth > 768 ? {backgroundImage: `url(${backgroundDes})`} : {backgroundImage: `url(${backgroundMob})`}}
            className={styles.contentWrapper}>
            <Logo/>
            <LeftNavBar isAuth={connected} />
            <RightNavBar isAuth={connected} />
            {connected && (
                <UserBalance />
            )}
            <MainButton connected={connected}/>
            <Balances isAuth={connected} />
            {connected && (
                <Animals/>
            )}
            {!connected && (
                <AllAnimals/>
            )}
            <Popups ConnectWallet={ConnectWallet}/>
        </div>
    )
}

export default Content;