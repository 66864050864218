import React, {useEffect, useState} from "react";
import styles from './BuyAnimalPopup.module.scss';
import level1 from "../../../assets/img/popups/DesktopLevel1.png";
import level2 from "../../../assets/img/popups/DesktopLevel2.png";
import level3 from "../../../assets/img/popups/DesktopLevel3.png";
import level4 from "../../../assets/img/popups/DesktopLevel4.png";
import level5 from "../../../assets/img/popups/DesktopLevel5.png";
import level6 from "../../../assets/img/popups/DesktopLevel6.png";
import level7 from "../../../assets/img/popups/DesktopLevel7.png";
import level8 from "../../../assets/img/popups/DesktopLevel8.png";
import level9 from "../../../assets/img/popups/DesktopLevel9.png";
import level10 from "../../../assets/img/popups/DesktopLevel10.png";
import level11 from "../../../assets/img/popups/DesktopLevel11.png";
import level12 from "../../../assets/img/popups/DesktopLevel12.png";
import level13 from "../../../assets/img/popups/DesktopLevel13.png";
import level1Mob from '../../../assets/img/mobilePopups/mobLevel1.png';
import level2Mob from '../../../assets/img/mobilePopups/mobLevel2.png';
import level3Mob from '../../../assets/img/mobilePopups/mobLevel3.png';
import level4Mob from '../../../assets/img/mobilePopups/mobLevel4.png';
import level5Mob from '../../../assets/img/mobilePopups/mobLevel5.png';
import level6Mob from '../../../assets/img/mobilePopups/mobLevel6.png';
import level7Mob from '../../../assets/img/mobilePopups/mobLevel7.png';
import level8Mob from '../../../assets/img/mobilePopups/mobLevel8.png';
import level9Mob from '../../../assets/img/mobilePopups/mobLevel9.png';
import level10Mob from '../../../assets/img/mobilePopups/mobLevel10.png';
import level11Mob from '../../../assets/img/mobilePopups/mobLevel11.png';
import level12Mob from '../../../assets/img/mobilePopups/mobLevel12.png';
import level13Mob from '../../../assets/img/mobilePopups/mobLevel13.png';
import buyBtn from "../../../assets/img/buyBtn.png";
import buyBtnMob from "../../../assets/img/buyAnimalsMob.png";
import Closer from "../../Common/Closer/Closer";
import {useSelector} from "react-redux";
import {getAnimals, getWalletAddress} from "../../../store/selectors";
import {getContract, getCookies, getProvider, refreshAnimals, sendBuyAnimal} from "../../../api/ethereum";
import {
    setActivePopup,
    setAnimals,
    setCoinsBalance,
    setCookiesBalance,
    setMyInvested,
    setMyWithdrawn
} from "../../../store/user-slice";
import {useAppDispatch} from "../../../hooks/redux";
import {usePopup} from "../../../hooks/useGlobalFunction";


const BuyAnimalPopup = () => {
    const address = useSelector(getWalletAddress);
    const dispatch = useAppDispatch();
    const animals = useSelector(getAnimals);
    const {setPopup} = usePopup();
    const [animalsLevel, setAnimalLevels] = useState(window.innerWidth > 767 ? `url(${level1})` : `url(${level1Mob})`);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {

        switch (animals.length) {
            case 0 :
                window.innerWidth > 767 ? setAnimalLevels(`url(${level1})`) : setAnimalLevels(`url(${level1Mob})`);
                break;
            case 1 :
                window.innerWidth > 767 ? setAnimalLevels(`url(${level2})`) : setAnimalLevels(`url(${level2Mob})`);
                break;
            case 2 :
                window.innerWidth > 767 ? setAnimalLevels(`url(${level3})`) : setAnimalLevels(`url(${level3Mob})`);
                break;
            case 3 :
                window.innerWidth > 767 ? setAnimalLevels(`url(${level4})`) : setAnimalLevels(`url(${level4Mob})`);
                break;
            case 4 :
                window.innerWidth > 767 ? setAnimalLevels(`url(${level5})`) : setAnimalLevels(`url(${level5Mob})`);
                break;
            case 5 :
                window.innerWidth > 767 ? setAnimalLevels(`url(${level6})`) : setAnimalLevels(`url(${level6Mob})`);
                break;
            case 6 :
                window.innerWidth > 767 ? setAnimalLevels(`url(${level7})`) : setAnimalLevels(`url(${level7Mob})`);
                break;
            case 7 :
                window.innerWidth > 767 ? setAnimalLevels(`url(${level8})`) : setAnimalLevels(`url(${level8Mob})`);
                break;
            case 8 :
                window.innerWidth > 767 ? setAnimalLevels(`url(${level9})`) : setAnimalLevels(`url(${level9Mob})`);
                break;
            case 9 :
                window.innerWidth > 767 ? setAnimalLevels(`url(${level10})`) : setAnimalLevels(`url(${level10Mob})`);
                break;
            case 10 :
                window.innerWidth > 767 ? setAnimalLevels(`url(${level11})`) : setAnimalLevels(`url(${level11Mob})`);
                break;
            case 11 :
                window.innerWidth > 767 ? setAnimalLevels(`url(${level12})`) : setAnimalLevels(`url(${level12Mob})`);
                break;
            case 12 :
                window.innerWidth > 767 ? setAnimalLevels(`url(${level13})`) : setAnimalLevels(`url(${level13Mob})`);
                break;
            case 13 :
                window.innerWidth > 767 ? setAnimalLevels(`url(${level13})`) : setAnimalLevels(`url(${level13Mob})`);
                break;
            default:
                break;
        }
    }, [animals])

    const onBuy = () => {
        getProvider().then(provider => {
            getContract(provider).then(contract => {
                provider.getSigner().then((signer) => {
                    setDisabled(true);
                    setPopup('start').then(() => dispatch(setActivePopup('transaction')));
                    sendBuyAnimal(contract, signer, animals.length)
                        .then((result) => {
                            if (result === false) {
                                setPopup('start').then(() => dispatch(setActivePopup('failed')));
                            } else if (result === 'error') {
                                setPopup('start').then(() => dispatch(setActivePopup('errorMessage')));
                            } else {
                                result.wait().then((response) => {
                                    if (response && response.status === 1) {
                                        refreshAnimals(provider, contract).then(response => {
                                            dispatch(setAnimals(response));
                                            getCookies(contract, address).then(result => {
                                                const cookies = result[0].toString();
                                                const coins = result[1].toString();
                                                const invested = result[8].toString();
                                                const withdrawn = result[9].toString();
                                                dispatch(setCookiesBalance(cookies));
                                                dispatch(setCoinsBalance(coins));
                                                dispatch(setMyInvested(invested));
                                                dispatch(setMyWithdrawn(withdrawn));
                                                setPopup('stop').then(() => dispatch(setActivePopup(null)));
                                                setPopup('start').then(() => dispatch(setActivePopup('success')));
                                            });
                                        })
                                    } else {
                                        setPopup('start').then(() => dispatch(setActivePopup('failed')));
                                    }
                                });
                            }
                        });
                })
            });
        });
    };

    return (
        <div style={{backgroundImage: animalsLevel}} className={animals.length < 4 ? styles.buyAnimal : styles.buyAnimalText}>
            <Closer/>
            {animals.length >= 13
                ? null
                : <div className={animals.length < 4 ? styles.buyAnimal__actions : styles.buyAnimalText__actions}
                       style={window.innerWidth > 767 ? {backgroundImage: `url(${buyBtn})`} : {backgroundImage: `url(${buyBtnMob})`}}>
                    <button disabled={disabled || animals.length >= 13} onClick={onBuy}>
                        <span>Buy animal</span>
                    </button>
                </div>}
        </div>
    )
}

export default BuyAnimalPopup;