import React, {useEffect, useState} from "react";
import LoginPopup from "../Login/LoginPopup";
import styles from './Popups.module.scss';
import {useSelector} from "react-redux";
import {getActivePopup} from "../../store/selectors";
import BuyPopup from "./BuyPopup/BuyPopup";
import GuidePopup from "./GuidePopup/GuidePopup";
import BuyAnimalPopup from "./BuyAnimalPopup/BuyAnimalPopup";
import FeedPopup from "./FeedPopup/FeedPopup";
import AccountPopup from "./AccountPopup/AccountPopup";
import FightPopup from "./FightPopup/FightPopup";
import ExitPopup from "./ExitPopup/ExitPopup";
import PartnersPopup from "./PartnersPopup/PartnersPopup";
import TransactionPopup from "./TransactionPopup/TransactionPopup";
import SuccessPopup from "./SuccessPopup/SuccessPopup";
import FailedPopup from "./FailedPopup/FailedPopup";
import AuditPopup from "./AuditPopup/AuditPopup";
import ErrorMessagePopup from "./ErrorMessagePopup/ErrorMessagePopup";
import CollectPopup from "./CollectPopup/CollectPopup";
import ActionEvent from "./ActionEvent/ActionEvent";

const Popups = ({ConnectWallet}) => {
    const activePopup = useSelector(getActivePopup);
    const [showPopup, setShowPopup] = useState(null);

    useEffect(() => {
        setShowPopup(activePopup);
    }, [activePopup]);

    return (
        <div className={showPopup ? styles.popupsWrapper : ''}>
            {showPopup === 'login' && (
                <LoginPopup ConnectWallet={ConnectWallet} />
            )}
            {showPopup === 'buy' && (
                <BuyPopup />
            )}
            {showPopup === 'guide' && (
                <GuidePopup />
            )}
            {showPopup === 'collect' && (
                <CollectPopup />
            )}
            {showPopup === 'buyAnimal' && (
                <BuyAnimalPopup />
            )}
            {showPopup === 'feed' && (
                <FeedPopup />
            )}
            {showPopup === 'account' && (
                <AccountPopup />
            )}
            {showPopup === 'fight' && (
                <FightPopup />
            )}
            {showPopup === 'exit' && (
                <ExitPopup />
            )}
            {showPopup === 'audit' && (
                <AuditPopup />
            )}
            {showPopup === 'partners' && (
                <PartnersPopup />
            )}
            {showPopup === 'transaction' && (
                <TransactionPopup />
            )}
            {showPopup === 'success' && (
                <SuccessPopup />
            )}
            {showPopup === 'failed' && (
                <FailedPopup />
            )}
            {showPopup === 'errorMessage' && (
                <ErrorMessagePopup />
            )}
            {showPopup === 'actionEvent' && (
                <ActionEvent />
            )}
        </div>
    )
}

export default Popups;