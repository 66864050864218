import React, {useEffect, useState} from "react";
import buyPopup from "../../../assets/img/popups/buy.png";
import buyPopupMob from "../../../assets/img/mobilePopups/defPopup.png";
import buyBtn from "../../../assets/img/buyBtn.png";
import btnInput from "../../../assets/img/btnInput.png";
import bnbIcon from "../../../assets/img/bnbIcon.svg";
import cookiesIcon from "../../../assets/img/cookies.png";
import styles from './BuyPopup.module.scss';
import Closer from "../../Common/Closer/Closer";
import {useSelector} from "react-redux";
import {getWalletAddress} from "../../../store/selectors";
import {getContract, getCookies, getPartner, getProvider, sendBuyCookies} from "../../../api/ethereum";
import {
    setActivePopup,
    setCoinsBalance,
    setCookiesBalance,
    setMyInvested,
    setMyWithdrawn
} from "../../../store/user-slice";
import {useAppDispatch} from "../../../hooks/redux";
import {usePopup} from "../../../hooks/useGlobalFunction";


const BuyPopup = () => {
    const walletAddress = useSelector(getWalletAddress);
    const dispatch = useAppDispatch();
    const {setPopup} = usePopup();
    const [bnbValue, setBnbValue] = useState(0);
    const [cookiesValue, setCookiesValue] = useState(0);
    const [disabled, setDisabled] = useState(false);
    const [partnerAddress, setPartnerAddress] = useState("0x0000000000000000000000000000000000000000")

    useEffect(() => {
        getPartner().then(r => {
            if (r) {
                setPartnerAddress(r);
            }
        });
    },[])

    const handleTransformBnb = (e) => {
        let inputValue = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
        const convertedValue = Math.floor(inputValue / 0.01);
        setCookiesValue(convertedValue);
        setBnbValue(inputValue)
    };

    const handleTransformCookies = (e) => {
        const inputValue = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
        const convertedValue = (inputValue * 0.01).toFixed(5);
        setBnbValue(convertedValue)
        setCookiesValue(inputValue)
    };

    const onSubmit = () => {
        getProvider().then(provider => {
            getContract(provider).then(contract => {
                provider.getSigner().then((signer) => {
                    setDisabled(true);
                    setPopup('start').then(() => dispatch(setActivePopup('transaction')));
                    sendBuyCookies(contract, signer, partnerAddress, bnbValue)
                        .then((result) => {
                            if (result === false) {
                                setPopup('start').then(() => dispatch(setActivePopup('failed')));
                                setDisabled(false);
                            } else {
                                result.wait().then((response) => {
                                    if (response && response.status === 1) {
                                        getCookies(contract, walletAddress).then(r => {
                                            const cookies = r[0].toString();
                                            const coins = r[1].toString();
                                            const invested = r[8].toString();
                                            const withdrawn = r[9].toString();
                                            dispatch(setCookiesBalance(cookies));
                                            dispatch(setCoinsBalance(coins));
                                            dispatch(setMyInvested(invested));
                                            dispatch(setMyWithdrawn(withdrawn));
                                            setPopup('stop').then(() => dispatch(setActivePopup(null)));
                                            setPopup('start').then(() => dispatch(setActivePopup('success')));
                                        });
                                    } else {
                                        setPopup('start').then(() => dispatch(setActivePopup('failed')));
                                    }
                                });
                                setDisabled(false);
                            }
                        });
                });
            });
        })
    }

    return (
        <div style={window.innerWidth > 767 ? {backgroundImage: `url(${buyPopup})`} : {backgroundImage: `url(${buyPopupMob})`}}
             className={styles.buyPopupWrapper}>
            <Closer />
            {window.innerWidth < 767 && (
                <h4>Buy cookies</h4>
            )}
            <div style={{backgroundImage: `url(${btnInput})`}} className={styles.bnbBalance}>
                <div className={styles.inputBox} style={{backgroundImage: `url(${bnbIcon})`}}>
                    <label htmlFor="bnb">BNB</label>
                    <input maxLength='5' id='bnb' name='bnb' type="text" value={bnbValue} onChange={handleTransformBnb} />
                </div>
            </div>
            <div style={{backgroundImage: `url(${btnInput})`}} className={styles.cookiesBalance}>
                <div className={styles.inputBox} style={{backgroundImage: `url(${cookiesIcon})`}}>
                    <label htmlFor="cookies">Cookies</label>
                    <input maxLength='5' id='cookies' name='cookies' type="text" value={cookiesValue} onChange={handleTransformCookies} />
                </div>
            </div>
            <div style={{backgroundImage: `url(${buyBtn})`}} className={styles.buy}>
                <button onClick={onSubmit} disabled={bnbValue === 0 || cookiesValue === 0 || disabled}>
                    <span>Buy</span>
                </button>
            </div>
        </div>
    )
}

export default BuyPopup;