import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    language: 'eng',
    walletAddress: '',
    walletBalance: 0,
    cookiesBalance: 0,
    coinsBalance: 0,
    myInvested: 0,
    myWithdrawn: 0,
    userId: '',
    activePopup: null,
    totalPlayers: 0,
    affiliate: {
        players: 0,
        cookies: 0,
        coins: 0
    },
    animals: []
};

export const userSlice = createSlice({
    name: 'userPage',
    initialState,
    reducers: {
        setActivePopup(state, {payload}) {
            state.activePopup = payload;
        },
        setWalletAddress(state, {payload}) {
            state.walletAddress = payload;
        },
        setWalletBalance(state, {payload}) {
            state.walletBalance = payload;
        },
        setCookiesBalance(state, {payload}) {
            state.cookiesBalance = payload;
        },
        setCoinsBalance(state, {payload}) {
            state.coinsBalance = payload;
        },
        setMyInvested(state, {payload}) {
            state.myInvested = payload;
        },
        setMyWithdrawn(state, {payload}) {
            state.myWithdrawn = payload;
        },
        setTotalPlayers(state, {payload}) {
            state.totalPlayers = payload;
        },
        setAffiliate(state, {payload}) {
            state.affiliate = payload;
        },
        setAnimals(state, {payload}) {
            state.animals = payload;
        }
    }
})

export default userSlice.reducer;

export const {
    setActivePopup,
    setWalletAddress,
    setWalletBalance,
    setCookiesBalance,
    setCoinsBalance,
    setAnimals,
    setTotalPlayers,
    setAffiliate,
    setMyInvested,
    setMyWithdrawn,
} = userSlice.actions;