import React from "react";
import styles from './NavBars.module.scss';
import account from '../../assets/img/icons/myAccount.svg';
import fight from '../../assets/img/icons/fight.svg';
import collect from '../../assets/img/icons/collect.svg';
import partners from '../../assets/img/icons/partners.svg';
import buttonIcon from '../../assets/img/buttonIcon.png';
import {useAppDispatch} from "../../hooks/redux";
import {setActivePopup} from "../../store/user-slice";
import {usePopup} from "../../hooks/useGlobalFunction";

const LeftNavBar = ({isAuth}) => {
    const dispatch = useAppDispatch();
    const {setPopup} = usePopup();

    const navigation = [
        {icon: account, text: 'My Account'},
        {icon: partners, text: 'Partners'},
        {icon: fight, text: 'Fight animals'},
        {icon: collect, text: 'Collect coins'},
    ]

    const onSubmit = (value) => {
        if (value === 'My Account') {
            setPopup('start').then(() => dispatch(setActivePopup('account')));
        }
        if (value === 'Fight animals') {
            setPopup('start').then(() => dispatch(setActivePopup('fight')));
        }
        if (value === 'Partners') {
            setPopup('start').then(() => dispatch(setActivePopup('partners')));
        }
        if (value === 'Collect coins') {
            setPopup('start').then(() => dispatch(setActivePopup('collect')));
        }
    }

    return (
        <div className={styles.leftNavBar}>
            {navigation && (
                <>
                    {navigation.map((item, index) =>
                        <div key={index} className={styles.leftNavBar__items}>
                            <button disabled={!isAuth} onClick={() => onSubmit(item.text)}
                                    style={{backgroundImage: `url(${buttonIcon})`}}>
                                <img src={item.icon} alt=""/>
                            </button>
                            <span>{item.text}</span>
                        </div>)}
                </>
            )}
        </div>
    )
}

export default LeftNavBar;