import React from "react";
import styles from './SuccessPopup.module.scss';
import successPopup from "../../../assets/img/popups/successPopup.png";
import Closer from "../../Common/Closer/Closer";
import buttonBG from "../../../assets/img/loginBtn.png";
import {setActivePopup} from "../../../store/user-slice";
import {usePopup} from "../../../hooks/useGlobalFunction";
import {useAppDispatch} from "../../../hooks/redux";
import popupImgMob from "../../../assets/img/mobilePopups/mobTransactionResult.png";

const SuccessPopup = () => {
    const {setPopup} = usePopup();
    const dispatch = useAppDispatch();

    const onBack = () => {
        setTimeout(() => {
            setPopup('stop').then(() => {dispatch(setActivePopup(null))})
        }, 50)
    }

    return (
        <div style={window.innerWidth > 767 ? {backgroundImage: `url(${successPopup})`} : {backgroundImage: `url(${popupImgMob})`}}
             className={styles.success}>
            <Closer />
            {window.innerWidth < 767 && (
                <h4>Transaction</h4>
            )}
            {window.innerWidth < 767 && (
                <p className={styles.text}>Transaction success</p>
            )}
            <div style={{backgroundImage: `url(${buttonBG})`}} className={styles.buttonWrapper}>
                <button onClick={onBack}>
                    <span>Back</span>
                </button>
            </div>
        </div>
    )
}

export default SuccessPopup;