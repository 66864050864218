import styles from './App.module.scss';
import React from "react";
import Content from "./components/Content";
import './fonts.css';
import background from "./assets/img/back.png";

function App() {
  return (
    <div className={styles.wrapper} style={{backgroundImage: `url(${background})`}}>
      <Content />
    </div>
  );
}

export default App;
