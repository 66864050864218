import React from "react";
import fight from "../../../assets/img/popups/fightP.png";
import BGIcon from '../../../assets/img/buttonIcon.png'
import fightPPMob from "../../../assets/img/mobilePopups/defPopup.png";
import styles from './FightPopup.module.scss';
import Closer from "../../Common/Closer/Closer";
import FightArena from "./FightArena";
import fightIcon from "../../../assets/img/icons/fight.svg";

const FightPopup = () => {
    return (
        <div style={window.innerWidth > 767 ? {backgroundImage: `url(${fight})`} : {backgroundImage: `url(${fightPPMob})`}}
             className={styles.fight}>
            <Closer/>
            {window.innerWidth < 767 && (
                <h2>Fight animals</h2>
            )}
            {window.innerWidth < 767 && (
                <div className={styles.icon} style={{backgroundImage: `url(${BGIcon})`}}>
                    <img src={fightIcon} alt=""/>
                </div>
            )}
            <p className={styles.text}>You have the option to send your animal to the Fight animals for battles against fellow users. Each
                battle has a 50% chance of victory and will start automatically.</p>
            <FightArena />
        </div>
    )
}

export default FightPopup;