import React from "react";
import styles from './NavBars.module.scss';
import buttonIcon from "../../assets/img/buttonIcon.png";
import audit from "../../assets/img/icons/audit.svg";
import telegram from "../../assets/img/icons/telegram.svg";
import twitter from "../../assets/img/icons/twitter.svg";
import {setActivePopup} from "../../store/user-slice";
import {useAppDispatch} from "../../hooks/redux";
import {usePopup} from "../../hooks/useGlobalFunction";
import guide from "../../assets/img/icons/guide.svg";

const RightNavBar = ({isAuth}) => {
    const dispatch = useAppDispatch();
    const {setPopup} = usePopup();

    const navigation = [
        {icon: audit, text: 'Audit', url: null},
        {icon: guide, text: 'Guide', url: null},
        {icon: telegram, text: 'Telegram channel', url: 'https://t.me/magicanimal_io'},
        {icon: twitter, text: 'twitter', url: 'https://x.com/magicanimal_io'},
    ]

    const onSubmit = (value) => {
        if (value === 'Guide') {
            setPopup('start').then(() => dispatch(setActivePopup('guide')));
        }
        if (value === 'Audit') {
            setPopup('start').then(() => dispatch(setActivePopup('audit')));
        }
    }

    return (
        <div className={styles.rightNavBar}>
            {navigation && (
                <>
                    {navigation.map((item, index) =>
                        <div key={index} className={styles.rightNavBar__items}>
                            <a disabled={!isAuth} onClick={() => onSubmit(item.text)} target='_blanc' href={item.url ? item.url : null}
                               style={{backgroundImage: `url(${buttonIcon})`}}>
                                <img src={item.icon} alt="" />
                            </a>
                            <span>{item.text}</span>
                        </div>)}
                </>
            )}
        </div>
    )
}

export default RightNavBar;