import React from "react";
import styles from './FightPopup.module.scss';
import Level from "./Level";

const FightArena = () => {
    const levels = [
        {level: 1, cost: 10},
        {level: 2, cost: 50},
        {level: 3, cost: 100},
        {level: 4, cost: 200},
        {level: 5, cost: 500},
    ]
    return (
        <div className={styles.arena}>
            <div className={styles.label}>
                <p>LVL</p>
                <p>COST</p>
            </div>
            {levels.map((level, index) => <Level level={level} key={index} index={index}/>)}
        </div>
    )
}

export default FightArena;