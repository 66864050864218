import React from "react";
import partnersPP from "../../../assets/img/popups/partnersPopup.png";
import partnersPPMob from "../../../assets/img/mobilePopups/defPopup.png";
import styles from './PartnersPopup.module.scss';
import Closer from "../../Common/Closer/Closer";
import accountProfile from "../../../assets/img/accountProfile.png";
import LinkCopy from "../AccountPopup/LinkCopy";
import RewardCopy from "./RewardStats";
import PlayerStats from "./PlayerStats";

const PartnersPopup = () => {
    return (
        <div style={window.innerWidth > 767 ? {backgroundImage: `url(${partnersPP})`} : {backgroundImage: `url(${partnersPPMob})`}}
             className={styles.partners}>
            <Closer />
            {window.innerWidth < 767 && (
                <h2>Affiliate program</h2>
            )}
            <img className={styles.icon} src={accountProfile} alt=""/>
            <LinkCopy />
            <RewardCopy />
            <PlayerStats />
        </div>
    )
}

export default PartnersPopup;