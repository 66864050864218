import React from "react";
import styles from './LoginPopup.module.scss';
import popupImg from '../../assets/img/popups/login.png';
import popupImgMob from '../../assets/img/mobilePopups/popuplittle.png';
import button from '../../assets/img/btnInput.png';
import Closer from "../Common/Closer/Closer";


const LoginPopup = ({ConnectWallet}) => {
    let injectedProvider = false

    if (typeof window.ethereum !== 'undefined') {
        injectedProvider = true
    }

    const isMetaMask = injectedProvider ? window.ethereum : false;

    return (
        <div
            style={window.innerWidth > 767 ? {backgroundImage: `url(${popupImg})`} : {backgroundImage: `url(${popupImgMob})`}}
            className={styles.loginPopup}>
            <Closer/>
            {window.innerWidth < 767 && (
                <h4>Login</h4>
            )}
            <h5>Please choose how to login </h5>
            <div className={styles.loginPopup__actions}>
                {isMetaMask ?
                    <div className={styles.buttonWrapper} style={{backgroundImage: `url(${button})`}}>
                        <button onClick={() => ConnectWallet()}>Connect Wallet</button>
                    </div>
                    : <p className={styles.wrong}> Please install MetaMask</p>
                }
            </div>
        </div>
    )
}

export default LoginPopup;