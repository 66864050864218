import React from "react";
import accountPopup from "../../../assets/img/popups/accountPopup.png";
import accountPopupMob from "../../../assets/img/mobilePopups/defPopup.png";
import styles from "./AccountPopup.module.scss";
import Closer from "../../Common/Closer/Closer";
import GameStats from "./GameStats";
import FightStats from "./FightStats";
import GlobalStatistics from "./GlobalStatistics";


const AccountPopup = () => {
    return (
        <div
            style={window.innerWidth > 767 ? {backgroundImage: `url(${accountPopup})`} : {backgroundImage: `url(${accountPopupMob})`}}
            className={styles.account}>
            {window.innerWidth < 767 && (
                <h4>My account</h4>
            )}
            <Closer/>
            <div className={styles.statsWrapper}>
                <GlobalStatistics />
                <GameStats/>
                <FightStats/>
            </div>
        </div>
    )
}

export default AccountPopup;