export const usePopup = () => {
    const setPopup = async (value) => {
        if (value === 'start' && window.innerWidth >= 430) {
            // document.body.style.overflow = "hidden";
            return true;
        }
        if (value === 'stop' && window.innerWidth >= 430) {
            // document.body.style.overflow = "auto";
            return false;
        }
    }

    return {
        setPopup
    }
}
