import React from "react";
import styles from "./Animals.module.scss";
import MeerkatGif from "../../assets/img/gifs/Meerkat.gif";
import CapybaraGif from "../../assets/img/gifs/Capybara.gif";
import TapirGif from "../../assets/img/gifs/Tapir.gif";
import KoalaGif from "../../assets/img/gifs/Koala.gif";
import OtterGif from "../../assets/img/gifs/Otter.gif";
import BeaverGif from "../../assets/img/gifs/Beaver.gif";
import PelicanGif from "../../assets/img/gifs/Pelican.gif";
import PeacockGif from "../../assets/img/gifs/Peacock.gif";
import RoeDeerGif from "../../assets/img/gifs/RoeDeer.gif";
import PandaGif from "../../assets/img/gifs/Panda.gif";
import GiraffeGif from "../../assets/img/gifs/Giraffe.gif";
import OstrichGif from "../../assets/img/gifs/Ostrich.gif";
import HippoGif from "../../assets/img/gifs/Hippo.gif";


const AllAnimals = () => {
    const animals = [
        {name: 'Meerkat', isAlive: true, timeFeed: 1},
        {name: 'Capybara', isAlive: true, timeFeed: 1},
        {name: 'Tapir', isAlive: true, timeFeed: 1},
        {name: 'Koala', isAlive: true, timeFeed: 1},
        {name: 'Otter', isAlive: true, timeFeed: 1},
        {name: 'Beaver', isAlive: true, timeFeed: 1},
        {name: 'Pelican', isAlive: true, timeFeed: 1},
        {name: 'Peacock', isAlive: true, timeFeed: 1},
        {name: 'Ostrich', isAlive: true, timeFeed: 1},
        {name: 'Roe deer', isAlive: true, timeFeed: 1},
        {name: 'Panda', isAlive: true, timeFeed: 1},
        {name: 'Meerkat', isAlive: true, timeFeed: 1},
        {name: 'Hippo', isAlive: true, timeFeed: 1},
    ]
    return (
        <div>
            {animals && (
                animals.map((animal, index) => (
                    <div key={index} className={styles.animalsContent}>
                        {animal.name === 'Meerkat' && (
                            <img className={styles.meerkat}
                                 src={MeerkatGif}
                                 alt=""
                            />
                        )}
                        {animal.name === 'Capybara' && (
                            <img
                                className={styles.capybara}
                                src={CapybaraGif}
                                alt=""
                            />
                        )}
                        {animal.name === 'Tapir' && (
                            <img className={styles.tapir}
                                 src={TapirGif}
                                 alt=""
                            />
                        )}
                        {animal.name === 'Koala' && (
                            <img className={styles.koala}
                                 src={KoalaGif}
                                 alt=""
                            />
                        )}
                        {animal.name === 'Otter' && (
                            <img className={styles.otter}
                                 src={OtterGif}
                                 alt=""
                            />
                        )}
                        {animal.name === 'Beaver' && (
                            <img className={styles.beaver}
                                 src={BeaverGif}
                                 alt=""
                            />
                        )}
                        {animal.name === 'Pelican' && (
                            <img className={styles.pelican}
                                 src={PelicanGif}
                                 alt=""
                            />
                        )}
                        {animal.name === 'Peacock' && (
                            <img className={styles.peacock}
                                 src={PeacockGif}
                                 alt=""
                            />
                        )}
                        {animal.name === 'Roe deer' && (
                            <img className={styles.roeDeer}
                                 src={RoeDeerGif}
                                 alt=""
                            />
                        )}
                        {animal.name === 'Panda' && (
                            <img className={styles.panda}
                                 src={PandaGif}
                                 alt=""
                            />
                        )}
                        {animal.name === 'Giraffe' && (
                            <img className={styles.giraffe}
                                 src={GiraffeGif}
                                 alt=""
                            />
                        )}
                        {animal.name === 'Ostrich' && (
                            <img className={styles.ostrich}
                                 src={OstrichGif}
                                 alt=""
                            />
                        )}
                        {animal.name === 'Hippo' && (
                            <img className={styles.hippo}
                                 src={HippoGif}
                                 alt=""
                            />
                        )}
                    </div>
                ))
            )}
        </div>
    )
}

export default AllAnimals;