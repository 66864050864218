import React from "react";
import {useSelector} from "react-redux";
import {getCoinsBalance, getCookiesBalance, getWalletAddress} from "../../store/selectors";
import styles from './Balances.module.scss';
import feedImg from '../../assets/img/feedBtn.png';
import balanceBtn from '../../assets/img/balancesBtn.png';
import cookies from '../../assets/img/cookies.png';
import coin from '../../assets/img/coin.png';
import {usePopup} from "../../hooks/useGlobalFunction";
import {
    setActivePopup,
    setCoinsBalance,
    setCookiesBalance,
    setMyInvested,
    setMyWithdrawn
} from "../../store/user-slice";
import {useAppDispatch} from "../../hooks/redux";
import {getContract, getCookies, getProvider, sendWithdrawCoins} from "../../api/ethereum";

const Balances = ({isAuth}) => {
    const {setPopup} = usePopup()
    const dispatch = useAppDispatch();
    const address = useSelector(getWalletAddress);

    const cookiesBalance = useSelector(getCookiesBalance);
    const coinsBalance = useSelector(getCoinsBalance);

    const onSubmit = (value) => {
        setPopup('start').then(() => dispatch(setActivePopup(value)));
    }

    const onWithdraw = () => {
        getProvider().then(provider => {
            getContract(provider).then(contract => {
                provider.getSigner().then((signer) => {
                    setPopup('start').then(() => dispatch(setActivePopup('transaction')));
                    sendWithdrawCoins(contract, signer, coinsBalance)
                        .then((result) => {
                            if (result === false) {
                                setPopup('start').then(() => dispatch(setActivePopup('failed')));
                            } else {
                                result.wait().then((response) => {
                                    if (response && response.status === 1) {
                                        getCookies(contract, address).then(result => {
                                            const cookies = result[0].toString();
                                            const coins = result[1].toString();
                                            const invested = result[8].toString();
                                            const withdrawn = result[9].toString();
                                            dispatch(setCookiesBalance(cookies));
                                            dispatch(setCoinsBalance(coins));
                                            dispatch(setMyInvested(invested));
                                            dispatch(setMyWithdrawn(withdrawn));
                                            setPopup('stop').then(() => dispatch(setActivePopup(null)));
                                            setPopup('start').then(() => dispatch(setActivePopup('success')));
                                        });
                                    } else {
                                        setPopup('start').then(() => dispatch(setActivePopup('failed')));
                                    }
                                });
                            };
                        });
                });
            });
        })
    }

    return isAuth ? (
        <div className={styles.balancesWrapper}>
            <div style={{backgroundImage: `url(${feedImg})`}} className={styles.feed}>
                <button onClick={() => setPopup('start').then(() => dispatch(setActivePopup('feed')))}>
                    Feed the animals
                </button>
            </div>
            <div style={{backgroundImage: `url(${balanceBtn})`}} className={styles.cookies}>
                <button onClick={() => onSubmit('buy')}>Buy cookies</button>
                <div>
                    <span>{cookiesBalance}</span>
                    <img src={cookies} alt=""/>
                </div>
            </div>
            <div style={{backgroundImage: `url(${balanceBtn})`}} className={styles.coins}>
                <button onClick={onWithdraw}>Withdraw coin</button>
                <div>
                    <span>{coinsBalance}</span>
                    <img src={coin} alt=""/>
                </div>
            </div>
        </div>
    ) : null
}

export default Balances;