import React from "react";
import close from "../../../assets/img/btnClose.png";
import button from "../../../assets/img/buyBtn.png";
import {useAppDispatch} from "../../../hooks/redux";
import {usePopup} from "../../../hooks/useGlobalFunction";
import {setActivePopup} from "../../../store/user-slice";
import styles from './Closer.module.scss'


const Closer = () => {
    const dispatch = useAppDispatch();
    const {setPopup} = usePopup()

    const StopPopup = () => {
        setTimeout(() => {
            setPopup('stop').then(() => {dispatch(setActivePopup(null))})
        }, 50)
    }

    return (
        <div>
            <button className={styles.close} onClick={() => StopPopup()}><img src={close} alt=""/></button>
        </div>
    )
}

export default Closer;