import React from "react";
import Closer from "../../Common/Closer/Closer";
import closePopup from "../../../assets/img/popups/closePopup.png";
import buttonBG from "../../../assets/img/loginBtn.png";
import styles from './ExitPopup.module.scss';
import {setActivePopup, setWalletAddress, setWalletBalance} from "../../../store/user-slice";
import {usePopup} from "../../../hooks/useGlobalFunction";
import {useAppDispatch} from "../../../hooks/redux";
import popupImgMob from "../../../assets/img/mobilePopups/popuplittle.png";

const ExitPopup = () => {
    const {setPopup} = usePopup();
    const dispatch = useAppDispatch();

    const onClose = () => {
        dispatch(setWalletBalance(0));
        dispatch(setWalletAddress(null));
        window.sessionStorage.removeItem("current_account");
        window.sessionStorage.removeItem("access_token");
    }

    const onBack = () => {
        setTimeout(() => {
            setPopup('stop').then(() => {dispatch(setActivePopup(null))})
        }, 50)
    }

    return (
        <div style={window.innerWidth > 767 ? {backgroundImage: `url(${closePopup})`} : {backgroundImage: `url(${popupImgMob})`}}
             className={styles.exit}>
            <Closer />
            {window.innerWidth < 767 && (
                <h4>Close the game</h4>
            )}
            <p className={styles.text}>
                Do you really want to close the game?
            </p>
            <div className={styles.actions}>
                <div style={{backgroundImage: `url(${buttonBG})`}}>
                    <button onClick={onClose}>
                        <span>Close</span>
                    </button>
                </div>
                <div style={{backgroundImage: `url(${buttonBG})`}}>
                    <button onClick={onBack}>
                        <span>Back</span>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ExitPopup;