import styles from "./AccountPopup.module.scss";
import buyBtn from "../../../assets/img/btnInput.png";
import buyBtnMob from "../../../assets/img/feedBtn.png";
import {CopyToClipboard} from "react-copy-to-clipboard";
import copy from "../../../assets/img/copy.png";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {getWalletAddress} from "../../../store/selectors";


const LinkCopy = () => {
    const id = useSelector(getWalletAddress);
    const address = `https://magicanimal.io/${id}`

    const [active, setActive] = useState(false);

    return (
        <div className={styles.link}
             style={window.innerWidth > 767 ? {backgroundImage: `url(${buyBtn})`} : {backgroundImage: `url(${buyBtnMob})`}}>
            <input className={active ? styles.copied : ''} type="text" readOnly='readonly' value={address}/>
            <CopyToClipboard className={styles.copyButton} text={address}>
                <button type='button' onClick={() => setActive(true)}>
                    <img src={copy} alt="copy"/>
                </button>
            </CopyToClipboard>
        </div>
    )
}

export default LinkCopy;