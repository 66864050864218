import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {getAnimals} from "../../store/selectors";
import styles from './Animals.module.scss';
import MeerkatGif from '../../assets/img/gifs/Meerkat.gif';
import MeerkatDied from '../../assets/img/animalDieds/MeerkatDied.png';
import MeerkatDiedMob from '../../assets/img/animalDieds/MeerkatDiedMob.png';
import CapybaraGif from '../../assets/img/gifs/Capybara.gif';
import CapybaraDied from '../../assets/img/animalDieds/CapybaraDied.png';
import CapybaraDiedMob from '../../assets/img/animalDieds/CapybaraDiedMob.png';
import TapirGif from '../../assets/img/gifs/Tapir.gif';
import TapirDied from '../../assets/img/animalDieds/TapirDied.png';
import TapirDiedMob from '../../assets/img/animalDieds/TapirDiedMob.png';
import KoalaGif from '../../assets/img/gifs/Koala.gif';
import KoalaDied from '../../assets/img/animalDieds/KoalaDied.png';
import KoalaDiedMob from '../../assets/img/animalDieds/KoalaDiedMob.png';
import OtterGif from '../../assets/img/gifs/Otter.gif';
import OtterDied from '../../assets/img/animalDieds/OtterDied.png';
import OtterDiedMob from '../../assets/img/animalDieds/OtterDiedMob.png';
import BeaverGif from '../../assets/img/gifs/Beaver.gif';
import BeaverDied from '../../assets/img/animalDieds/BeaverDied.png';
import BeaverDiedMob from '../../assets/img/animalDieds/BeaverDiedMob.png';
import PelicanGif from '../../assets/img/gifs/Pelican.gif';
import PelicanDied from '../../assets/img/animalDieds/PelicanDied.png';
import PelicanDiedMob from '../../assets/img/animalDieds/PelicanDiedMob.png';
import PeacockGif from '../../assets/img/gifs/Peacock.gif';
import PeacockDied from '../../assets/img/animalDieds/PeacockDied.png';
import PeacockDiedMob from '../../assets/img/animalDieds/PeacockDiedMob.png';
import OstrichGif from '../../assets/img/gifs/Ostrich.gif';
import OstrichDied from '../../assets/img/animalDieds/OstrichDies.png';
import OstrichDiedMob from '../../assets/img/animalDieds/OstrichDiesMob.png';
import RoeDeerGif from '../../assets/img/gifs/RoeDeer.gif';
import RoeDeerDied from '../../assets/img/animalDieds/RoeDeerDied.png';
import RoeDeerDiedMob from '../../assets/img/animalDieds/RoeDeerDiedMob.png';
import PandaGif from '../../assets/img/gifs/Panda.gif';
import PandaDied from '../../assets/img/animalDieds/PandaDied.png';
import PandaDiedMob from '../../assets/img/animalDieds/PandaDiedMob.png';
import GiraffeGif from '../../assets/img/gifs/Giraffe.gif';
import GiraffeDied from '../../assets/img/animalDieds/GiraffeDied.png';
import GiraffeDiedMob from '../../assets/img/animalDieds/GiraffeDiedMob.png';
import HippoGif from '../../assets/img/gifs/Hippo.gif';
import HippoDied from '../../assets/img/animalDieds/HippoDied.png';
import HippoDiedMob from '../../assets/img/animalDieds/HippoDiedMob.png';
import {getContract, getProvider, refreshAnimals} from "../../api/ethereum";
import {useAppDispatch} from "../../hooks/redux";
import {setAnimals} from "../../store/user-slice";


const Animals = () => {
    const dispatch = useAppDispatch();
    const [provider, setProvider] = useState(null);
    const [contract, setContract] = useState(null);

    useEffect(() => {
        getProvider().then(r => setProvider(r))
    }, []);

    useEffect(() => {
        getContract(provider).then(r => setContract(r));
    }, [provider]);

    const animals = useSelector(getAnimals);

    useEffect(() => {
        if (provider && contract) {
            refreshAnimals(provider, contract).then(r => dispatch(setAnimals(r)))
        }
    }, [provider, contract]);

    return (
        <div className={styles.animals}>
            {animals && (
                animals.map((animal, index) => (
                    <div key={index} className={styles.animalsContent}>
                        {animal.name === 'Meerkat' && (
                            <img className={animal.isAlive ? styles.meerkat : styles.meerkatDied}
                                 src={animal.isAlive ? MeerkatGif : window.innerWidth > 767 ? MeerkatDied : MeerkatDiedMob}
                                 alt=""
                            />
                        )}
                        {animal.name === 'Capybara' && (
                            <img
                                className={animal.isAlive ? styles.capybara : styles.capybaraDied}
                                src={animal.isAlive ? CapybaraGif : window.innerWidth > 767 ? CapybaraDied : CapybaraDiedMob}
                                alt=""
                            />
                        )}
                        {animal.name === 'Tapir' && (
                            <img className={animal.isAlive ? styles.tapir : styles.tapirDied}
                                 src={animal.isAlive ? TapirGif : window.innerWidth > 767 ? TapirDied : TapirDiedMob}
                                 alt=""
                            />
                        )}
                        {animal.name === 'Koala' && (
                            <img className={animal.isAlive ? styles.koala : styles.koalaDied}
                                 src={animal.isAlive ? KoalaGif : window.innerWidth > 767 ? KoalaDied : KoalaDiedMob}
                                 alt=""
                            />
                        )}
                        {animal.name === 'Otter' && (
                            <img className={animal.isAlive ? styles.otter : styles.otterDied}
                                 src={animal.isAlive ? OtterGif : window.innerWidth > 767 ? OtterDied : OtterDiedMob}
                                 alt=""
                            />
                        )}
                        {animal.name === 'Beaver' && (
                            <img className={animal.isAlive ? styles.beaver : styles.beaverDied}
                                 src={animal.isAlive ? BeaverGif : window.innerWidth > 767 ? BeaverDied : BeaverDiedMob}
                                 alt=""
                            />
                        )}
                        {animal.name === 'Pelican' && (
                            <img className={animal.isAlive ? styles.pelican : styles.pelicanDied}
                                 src={animal.isAlive ? PelicanGif : window.innerWidth > 767 ? PelicanDied : PelicanDiedMob}
                                 alt=""
                            />
                        )}
                        {animal.name === 'Peacock' && (
                            <img className={animal.isAlive ? styles.peacock : styles.peacockDied}
                                 src={animal.isAlive ? PeacockGif : window.innerWidth > 767 ? PeacockDied : PeacockDiedMob}
                                 alt=""
                            />
                        )}
                        {animal.name === 'Roe deer' && (
                            <img className={animal.isAlive ? styles.roeDeer : styles.roeDeerDied}
                                 src={animal.isAlive ? RoeDeerGif : window.innerWidth > 767 ? RoeDeerDied : RoeDeerDiedMob}
                                 alt=""
                            />
                        )}
                        {animal.name === 'Panda' && (
                            <img className={animal.isAlive ? styles.panda : styles.pandaDied}
                                 src={animal.isAlive ? PandaGif : window.innerWidth > 767 ? PandaDied : PandaDiedMob}
                                 alt=""
                            />
                        )}
                        {animal.name === 'Giraffe' && (
                            <img className={animal.isAlive ? styles.giraffe : styles.giraffeDied}
                                 src={animal.isAlive ? GiraffeGif : window.innerWidth > 767 ? GiraffeDied : GiraffeDiedMob}
                                 alt=""
                            />
                        )}
                        {animal.name === 'Ostrich' && (
                            <img className={animal.isAlive ? styles.ostrich : styles.ostrichDied}
                                 src={animal.isAlive ? OstrichGif : window.innerWidth > 767 ? OstrichDied : OstrichDiedMob}
                                 alt=""
                            />
                        )}
                        {animal.name === 'Hippo' && (
                            <img className={animal.isAlive ? styles.hippo : styles.hippoDied}
                                 src={animal.isAlive ? HippoGif : window.innerWidth > 767 ? HippoDied : HippoDiedMob}
                                 alt=""
                            />
                        )}
                    </div>
                ))
            )}
        </div>
    )
}

export default Animals;