import React, {useEffect, useState} from "react";
import styles from './AccountPopup.module.scss';
import fightIcon from '../../../assets/img/fightAnimals.svg'
import {getContract, getProvider} from "../../../api/ethereum";
import {useSelector} from "react-redux";
import {getWalletAddress} from "../../../store/selectors";

const FightStats = () => {
    const address = useSelector(getWalletAddress);
    const [totalGames, setTotalGames] = useState(0);
    const [totalWins, setTotalWins] = useState(0);
    const [totalLoss, setTotalLoss] = useState(0);
    const [gameInProcess, setGameInProcess] = useState(0);

    useEffect(() => {
        getProvider().then(provider => {
            getContract(provider).then(contract => {
                provider.getSigner().then((signer) => {
                    contract.connect(signer).wars(address).then((result) => {
                        const games = result[0].toString();
                        const wins = result[1].toString();
                        const loss = result[2].toString();
                        setTotalGames(games);
                        setTotalWins(wins);
                        setTotalLoss(loss);
                        setGameInProcess(games - wins - loss)
                    })
                })
            })
        })
    }, [])

    return (
        <div className={styles.fightStats}>
            <div className={styles.statsHeader}>
                <h3>{window.innerWidth > 767 ? 'Fight animals 1 vs 1' : 'Fight animals'}</h3>
            </div>
            <div className={styles.fightStats__boxFirst}>
                <p>Total Games</p>
                <p className={styles.fightStats__value}>{totalGames}</p>
            </div>
            <div className={styles.fightStats__box}>
                <p>Total Wins/Loss</p>
                <p className={styles.fightStats__value}>{totalWins}/{totalLoss}</p>
            </div>
            <div className={styles.fightStats__box}>
                <p>Game in Process</p>
                <p className={styles.fightStats__value}>{gameInProcess}</p>
            </div>
        </div>
    )
}

export default FightStats;