import React from "react";
import styles from './CollectPopup.module.scss';
import {usePopup} from "../../../hooks/useGlobalFunction";
import {useAppDispatch} from "../../../hooks/redux";
import {
    setActivePopup,
    setAffiliate,
    setCoinsBalance,
    setCookiesBalance,
    setMyInvested, setMyWithdrawn
} from "../../../store/user-slice";
import failedPopup from "../../../assets/img/popups/errorPopup.png";
import popupImgMob from "../../../assets/img/mobilePopups/mobTransactionResult.png";
import Closer from "../../Common/Closer/Closer";
import buttonBG from "../../../assets/img/loginBtn.png";
import {useSelector} from "react-redux";
import {getWalletAddress} from "../../../store/selectors";
import {getContract, getCookies, getProvider, sendCollectCoins} from "../../../api/ethereum";

const CollectPopup = () => {
    const {setPopup} = usePopup();
    const dispatch = useAppDispatch();
    const address = useSelector(getWalletAddress);

    const onSubmit = () => {
        getProvider().then(provider => {
            getContract(provider).then(contract => {
                provider.getSigner().then((signer) => {
                    setPopup('start').then(() => dispatch(setActivePopup('transaction')));
                    sendCollectCoins(contract, signer)
                        .then((result) => {
                            if (result === false) {
                                setPopup('start').then(() => dispatch(setActivePopup('failed')));
                            } else {
                                result.wait().then((response) => {
                                    if (response && response.status === 1) {
                                        getCookies(contract, address).then(r => {
                                            const cookies = r[0].toString();
                                            const coins = r[1].toString();
                                            const invested = r[8].toString();
                                            const withdrawn = r[9].toString();
                                            const affiliate = {
                                                players: r[5].toString(),
                                                coins: r[6].toString(),
                                                cookies: r[7].toString(),
                                            }
                                            dispatch(setCookiesBalance(cookies));
                                            dispatch(setCoinsBalance(coins));
                                            dispatch(setAffiliate(affiliate));
                                            dispatch(setMyInvested(invested));
                                            dispatch(setMyWithdrawn(withdrawn));
                                            setPopup('stop').then(() => dispatch(setActivePopup(null)));
                                            setPopup('start').then(() => dispatch(setActivePopup('success')));
                                        });
                                    }
                                });
                            }
                        })
                })
            })
        })
    }

    return (
        <div
            style={window.innerWidth > 767 ? {backgroundImage: `url(${failedPopup})`} : {backgroundImage: `url(${popupImgMob})`}}
            className={styles.failed}>
            <Closer/>
            {window.innerWidth < 767 && (
                <h4>Transaction</h4>
            )}
            <p className={styles.text}>Animals form coins every hour. If you haven’t earned any coins, come back in an hour <span className={styles.deco}></span>🦚</p>
            <div style={{backgroundImage: `url(${buttonBG})`}} className={styles.collectPopup}>
                <button onClick={() => onSubmit()}>
                    <span>Okay</span>
                </button>
            </div>
        </div>
    )
}

export default CollectPopup;