import React from "react";
import styles from './MainButton.module.scss';
import loginBtn from '../../assets/img/loginBtn.png';
import buyBtn from '../../assets/img/buyBtn.png';
import buyBtnMob from '../../assets/img/buyAnimalsMob.png';
import {usePopup} from "../../hooks/useGlobalFunction";
import {useAppDispatch} from "../../hooks/redux";
import {setActivePopup} from "../../store/user-slice";
import {useSelector} from "react-redux";
import {getAnimals} from "../../store/selectors";


const MainButton = ({connected}) => {
    const dispatch = useAppDispatch();
    const {setPopup} = usePopup();
    const animals = useSelector(getAnimals);

    return (
        <>
            {animals.length >= 13 && animals.length
                ? null
                : <div className={connected ? styles.buy : styles.login}
                       style={connected && window.innerWidth >= 768
                           ? {backgroundImage: `url(${buyBtn})`} : connected && window.innerWidth < 768
                               ? {backgroundImage: `url(${buyBtnMob})`}
                               : {backgroundImage: `url(${loginBtn})`}}>
                    <button
                        onClick={() => connected
                            ? setPopup('start').then(() => dispatch(setActivePopup('buyAnimal')))
                            : setPopup('start').then(() => dispatch(setActivePopup('login')))}>
                        <span>{connected ? 'Buy animal' : 'Login'}</span>
                    </button>
                </div>}
        </>
    )
}

export default MainButton;