import React, {useEffect, useState} from "react";
import Meerkat from '../../../assets/img/animalIcons/Meerkat.svg';
import Capybara from '../../../assets/img/animalIcons/Capybara.svg';
import Tapir from '../../../assets/img/animalIcons/Tapir.svg';
import Koala from '../../../assets/img/animalIcons/Koala.svg';
import Otter from '../../../assets/img/animalIcons/Otter.svg';
import Beaver from '../../../assets/img/animalIcons/Beaver.svg';
import Pelican from '../../../assets/img/animalIcons/Pelican.svg';
import Peacock from '../../../assets/img/animalIcons/Peacock.svg';
import Ostrich from '../../../assets/img/animalIcons/Ostrich.svg';
import RoeDeer from '../../../assets/img/animalIcons/RoeDeer.svg';
import Panda from '../../../assets/img/animalIcons/Panda.svg';
import Giraffe from '../../../assets/img/animalIcons/Giraffe.svg';
import Hippo from '../../../assets/img/animalIcons/Hippo.svg';
import styles from "./FeedPopup.module.scss";
import btnLittle from "../../../assets/img/btnLittle.png";
import {getContract, getCookies, getProvider, refreshAnimals, sendFeed} from "../../../api/ethereum";
import {
    setActivePopup,
    setAnimals,
    setCoinsBalance,
    setCookiesBalance,
    setMyInvested,
    setMyWithdrawn
} from "../../../store/user-slice";
import {ethers} from "ethers";
import {useAppDispatch} from "../../../hooks/redux";
import {usePopup} from "../../../hooks/useGlobalFunction";
import {useSelector} from "react-redux";
import {getWalletAddress} from "../../../store/selectors";


const Animal = ({animal, id}) => {
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const dispatch = useAppDispatch();
    const address = useSelector(getWalletAddress);
    const {setPopup} = usePopup();

    const timeBuy = Math.round(new Date().getTime() / 1000) - animal.timeFeed;
    const timeLeft = 259200 - timeBuy;

    const startTimer = (value, time) => {
        if (value === 'minutes') {
            setTimeout(() => {
                setMinutes(time - 1);
                if (time !== 0) {
                    startTimer('minutes', time)
                }
            }, 60000)
        }
    }

    const setTime = () => {
        const time = Math.floor(timeLeft / 3600);

        if (time === 0) {
            setHours(Math.round(0));
            setMinutes(Math.floor(timeLeft / 60));
            startTimer('minutes', Math.floor(timeLeft / 60));
        } else {
            setHours(Math.round(time));
            startTimer('hours',time);
        }
    }

    const onFeed = (value) => {
        getProvider().then(provider => {
            getContract(provider).then(contract => {
                provider.getSigner().then((signer) => {
                    setPopup('start').then(() => dispatch(setActivePopup('transaction')));
                    sendFeed(contract, signer, value)
                        .then((result) => {
                            if (result === false) {
                                setPopup('start').then(() => dispatch(setActivePopup('failed')));
                            } else {
                                result.wait().then((response) => {
                                    if (response && response.status === 1) {
                                        refreshAnimals(provider, contract).then(response => {
                                            dispatch(setAnimals(response));
                                            getCookies(contract, address).then(result => {
                                                const cookies = result[0].toString();
                                                const coins = result[1].toString();
                                                const invested = result[8].toString();
                                                const withdrawn = result[9].toString();
                                                dispatch(setCookiesBalance(cookies));
                                                dispatch(setCoinsBalance(coins));
                                                dispatch(setMyInvested(invested));
                                                dispatch(setMyWithdrawn(withdrawn));
                                                setPopup('stop').then(() => dispatch(setActivePopup(null)));
                                                setPopup('start').then(() => dispatch(setActivePopup('success')));
                                            });
                                        })
                                    } else {
                                        setPopup('start').then(() => dispatch(setActivePopup('failed')));
                                    }
                                });
                            }
                        });
                });
            });
        })
    }

    useEffect(() => {
        setTime()
    }, [])

    let image;

    const setImage = (name) => {
        if (name === 'Meerkat') {
            image = Meerkat;
        }
        if (name === 'Capybara') {
            image = Capybara;
        }
        if (name === 'Tapir') {
            image = Tapir;
        }
        if (name === 'Koala') {
            image = Koala;
        }
        if (name === 'Otter') {
            image = Otter;
        }
        if (name === 'Beaver') {
            image = Beaver;
        }
        if (name === 'Pelican') {
            image = Pelican;
        }
        if (name === 'Peacock') {
            image = Peacock;
        }
        if (name === 'Ostrich') {
            image = Ostrich;
        }
        if (name === 'Roe deer') {
            image = RoeDeer;
        }
        if (name === 'Panda') {
            image = Panda;
        }
        if (name === 'Giraffe') {
            image = Giraffe;
        }
        if (name === 'Hippo') {
            image = Hippo;
        }
    }

    if (animal?.name) {
        setImage(animal.name);
    }

    return (
        <div className={styles.animalsTable}>
            <div className={!animal.isAlive ? styles.animalsTable__animalsDead : styles.animalsTable__animals}>
                <img src={image} alt=""/> <span>{animal.name}</span>
            </div>
            <p className={styles.animalsTable__hours}>
                {animal.isAlive && (
                    <span>{hours === 0 ? `${minutes}m` : `${hours}h`}</span>
                )}
            </p>
            <div className={styles.animalsTable__buttons}>
                {!animal.isAlive || (hours === 0 && minutes === 0)
                    ? <p className={styles.dead}>Dead</p>
                    : <div style={{backgroundImage: `url(${btnLittle})`}} className={styles.buttonFeed}>
                        <button onClick={() => onFeed(id)}>
                            <span>Feed</span>
                        </button>
                    </div>
                }
            </div>
        </div>
    )
}

export default Animal;