import React from "react";
import partnersPP from "../../../assets/img/popups/partnersPopup.png";
import partnersPPMob from "../../../assets/img/mobilePopups/defPopup.png";
import styles from "./ActionEvent.module.scss";
import Closer from "../../Common/Closer/Closer";
import buyBtn from "../../../assets/img/btnInput.png";
import buyBtnMob from "../../../assets/img/feedBtn.png";


const ActionEvent = () => {
    return (
        <div
            style={window.innerWidth > 767 ? {backgroundImage: `url(${partnersPP})`} : {backgroundImage: `url(${partnersPPMob})`}}
            className={styles.actions}>
            <Closer/>
            {window.innerWidth < 767 && (
                <h2>Affiliate program</h2>
            )}
            <p className={styles.actions__text}>For every new referral, you will receive <span className={styles.strongGreen}>$10!</span> To participate in the promotion, you need to send a
                screenshot of your current referrals to @MagicAnimalADM . The promotion is valid from 01.04.2024 to
                10.04.2024.
            </p>
            <div className={styles.actions__link} style={window.innerWidth > 767 ? {backgroundImage: `url(${buyBtn})`} : {backgroundImage: `url(${buyBtnMob})`}}>
                <a href="https://t.me/MagicAnimalADM" target='_blank'>
                    <span>Write to Admin</span>
                </a>
            </div>
        </div>
    )
}

export default ActionEvent;