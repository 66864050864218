import React from "react";
import styles from './Logo.module.scss';
import logo from '../../assets/img/logo.png';

const Logo = () => {
    return (
        <div className={styles.logo}>
            <img src={logo} alt=""/>
        </div>
    )
}

export default Logo;