import React from "react";
import styles from "./FeedPopup.module.scss";
import Animal from "./Animal";
import {useSelector} from "react-redux";
import {getAnimals} from "../../../store/selectors";


const AnimalList = () => {
    const animals = useSelector(getAnimals);
    return (
        <div className={styles.animalsWrapper}>
            <div className={styles.animalsLabels}>
                <div className={styles.animalsLabels__animals}>
                    <span>Animals</span>
                </div>
                <p className={styles.animalsTable__hours}>Hours</p>
                <p className={styles.animalsTable__buttons}>Feed the animals</p>
            </div>
            {animals && (
                animals.map((animal, index) => <Animal animal={animal} key={index} id={index} />)
            )}
        </div>
    )
}

export default AnimalList;