import React from "react";
import styles from './AccountPopup.module.scss';
import {useSelector} from "react-redux";
import {getAnimals, getInvested, getWithdrawn} from "../../../store/selectors";
import bnbIcon from '../../../assets/img/bnbIcon.svg'
import animalIcon from '../../../assets/img/animaIcon.svg'

const GameStats = () => {
    const { ethers } = require("ethers");
    const animals = useSelector(getAnimals);
    const invested = useSelector(getInvested);
    const withdrawn = useSelector(getWithdrawn);


    return (
        <div className={`${styles.gameStats} ${styles.gameStatsWrapper}`}>
            <div className={styles.statsHeader}>
                <h3>My statistics</h3>
            </div>
            <div className={styles.gameStats__box}>
                <p className={styles.gameStats__label}>My animals</p>
                <p className={styles.gameStats__value}><img src={animalIcon} alt=""/><span>{animals.length}/13</span></p>
            </div>
            <div className={styles.gameStats__box}>
                <p className={styles.gameStats__label}>My invested</p>
                <p className={styles.gameStats__value}><img src={bnbIcon} alt=""/><span>{ethers.formatEther(invested)}</span></p>
            </div>
            <div className={styles.gameStats__boxSecond}>
                <p className={styles.gameStats__label}>My withdrawn</p>
                <p className={styles.gameStats__value}><img src={bnbIcon} alt=""/><span>{ethers.formatEther(withdrawn)}</span></p>
            </div>
        </div>
    )
}

export default GameStats;