import React from "react";
import styles from "./PartnersPopup.module.scss";
import cookies from "../../../assets/img/cookies.png";
import coin from "../../../assets/img/coin.png";


const RewardStats = () => {
    return (
        <div className={styles.statsWrapper}>
            <div className={styles.statsBox}>
                <p className={styles.statsBox__head}>Reward</p>
                <p className={styles.statsBox__headValue}>Unit on this LVL</p>
            </div>
            <div className={styles.statsBox}>
                <p className={styles.statsBox__text}>LVL 1</p>
                <div className={styles.statsBox__value}>
                    <span>7%</span><img src={cookies} alt=""/><span>and 3%</span><img src={coin} alt=""/>
                </div>
            </div>
            <div className={styles.statsBox}>
                <p className={styles.statsBox__text}>LVL 2</p>
                <div className={styles.statsBox__value}>
                    <span>2%</span><img src={cookies} alt=""/><span>and 0.9%</span><img src={coin} alt=""/>
                </div>
            </div>
        </div>
    )
}

export default RewardStats;