import React from "react";
import transactionPopup from "../../../assets/img/popups/transactionPopup.png";
import styles from './TransactionPopup.module.scss';
import Closer from "../../Common/Closer/Closer";
import popupImgMob from "../../../assets/img/mobilePopups/mobTransactionResult.png";
import text from "../../../assets/img/mobilePopups/transactionText.svg";

const TransactionPopup = () => {
    return (
        <div style={window.innerWidth > 767 ? {backgroundImage: `url(${transactionPopup})`} : {backgroundImage: `url(${popupImgMob})`}}
             className={styles.transaction}>
            <Closer />
            {window.innerWidth < 767 && (
                <h4>Transaction</h4>
            )}
            {window.innerWidth < 767 && (
                <img className={styles.text} src={text} alt=""/>
            )}
        </div>
    )
}

export default TransactionPopup;