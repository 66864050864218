import React, {useEffect, useState} from "react";
import styles from "./AccountPopup.module.scss";
import animalIcon from "../../../assets/img/animaIcon.svg";
import bnbIcon from "../../../assets/img/bnbIcon.svg";
import accountIcon from '../../../assets/img/accountIcon.svg'
import {getContract, getGlobalStats, getProvider, } from "../../../api/ethereum";


const GlobalStatistics = () => {
    const [provider, setProvider] = useState(null);
    const [contract, setContract] = useState(null);
    const [animals, setAnimals] = useState();
    const [players, setPlayers] = useState();
    const [invested, setInvested] = useState();
    const [withdrawn, setWithdrawn] = useState();
    const { ethers } = require("ethers");



    useEffect(() => {
        getProvider().then(r => setProvider(r))
    }, []);

    useEffect(() => {
        getContract(provider).then(r => setContract(r));
    }, [provider]);

    useEffect(() => {
        if (provider && contract) {
            getGlobalStats(contract).then(r => {
                const totalAnimals = r[1].toString();
                const totalPlayers = r[0].toString();
                const totalInvested = r[2].toString();
                const totalWithdrawn = r[3].toString();
                const newTotalInvested = ethers.formatEther(totalInvested);
                const newTotalWithdrawn = ethers.formatEther(totalWithdrawn);
                setAnimals(totalAnimals);
                setPlayers(totalPlayers);
                setInvested(newTotalInvested);
                setWithdrawn(newTotalWithdrawn);
            });
        }
    }, [provider, contract]);

    return (
        <div className={`${styles.gameStats} ${styles.globalStats}`}>
            <div className={styles.statsHeader}>
                <h3>Global statistics</h3>
            </div>
            <div className={styles.gameStats__box}>
                <p className={styles.gameStats__label}>Total animals </p>
                <p className={styles.gameStats__value}><img src={animalIcon} alt=""/><span>{animals}</span></p>
            </div>
            <div className={styles.gameStats__box}>
                <p className={styles.gameStats__label}>Total players</p>
                <p className={styles.gameStats__value}><img src={accountIcon} alt=""/><span>{players}</span></p>
            </div>
            <div className={styles.gameStats__box}>
                <p className={styles.gameStats__label}>Total invested</p>
                <p className={styles.gameStats__value}><img src={bnbIcon} alt=""/><span>{invested}</span></p>
            </div>
            <div className={styles.gameStats__boxSecond}>
                <p className={styles.gameStats__label}>Total withdrawn</p>
                <p className={styles.gameStats__value}><img src={bnbIcon} alt=""/><span>{withdrawn}</span></p>
            </div>
        </div>

    )
}

export default GlobalStatistics;