import React from "react";
import {usePopup} from "../../../hooks/useGlobalFunction";
import {useAppDispatch} from "../../../hooks/redux";
import {setActivePopup} from "../../../store/user-slice";
import popupImgMob from "../../../assets/img/mobilePopups/mobTransactionResult.png";
import failedPopup from "../../../assets/img/popups/errorPopup.png";
import styles from './ErrorMessagePopup.module.scss';
import buttonBG from "../../../assets/img/loginBtn.png";
import Closer from "../../Common/Closer/Closer";

const ErrorMessagePopup = () => {
    const {setPopup} = usePopup();
    const dispatch = useAppDispatch();

    const onBack = () => {
        setTimeout(() => {
            setPopup('stop').then(() => {
                dispatch(setActivePopup(null))
            })
        }, 50)
    }

    return (
        <div
            style={window.innerWidth > 767 ? {backgroundImage: `url(${failedPopup})`} : {backgroundImage: `url(${popupImgMob})`}}
            className={styles.failed}>
            <Closer/>
            {window.innerWidth < 767 && (
                <h4>Transaction</h4>
            )}
            <p className={styles.text}>Not enough cookies, click “Buy cookies” to purchase cookies.</p>
            <div style={{backgroundImage: `url(${buttonBG})`}} className={styles.errorMessagePopup}>
                <button onClick={onBack}>
                    <span>Back</span>
                </button>
            </div>
        </div>
    )
}

export default ErrorMessagePopup;