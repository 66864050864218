import React from "react";
import Closer from "../../Common/Closer/Closer";
import styles from './GuidePopup.module.scss';
import guidePopup from "../../../assets/img/popups/guidePopup.png";
import guidePopupMob from "../../../assets/img/mobilePopups/defPopup.png";
import btnInput from "../../../assets/img/btnInput.png";
import ch from '../../../pdf/Magic Zoo CH_compressed.pdf';
import en from '../../../pdf/Magic Zoo EN_compressed.pdf';
import ru from '../../../pdf/Magic Zoo RU_compressed.pdf';
import sp from '../../../pdf/Magic Zoo SP_compressed.pdf';

const GuidePopup = () => {
    const guides = [
        {name: 'English', link: en},
        {name: 'Spanish', link: sp},
        {name: 'Chinese', link: ch},
        {name: 'Ru', link: ru}
    ]

    return (
        <div style={window.innerWidth > 767 ? {backgroundImage: `url(${guidePopup})`} : {backgroundImage: `url(${guidePopupMob})`}}
             className={styles.guide}>
            <Closer />
            {window.innerWidth < 767 && (
                <h2>Guide</h2>
            )}
            <div className={styles.guide__list}>
                {guides && (
                    guides.map((item, index) => (
                        <div className={styles.guide__buttonLink} key={index} style={{backgroundImage: `url(${btnInput})`}}>
                            <a href={item.link} target='_blank'>
                                <span>{item.name}</span>
                            </a>
                        </div>
                    ))
                )}
            </div>
        </div>
    )
}

export default GuidePopup;