import React from "react";
import failedPopup from "../../../assets/img/popups/failedPopup.png";
import styles from './FailedPopup.module.scss';
import Closer from "../../Common/Closer/Closer";
import {usePopup} from "../../../hooks/useGlobalFunction";
import {useAppDispatch} from "../../../hooks/redux";
import {setActivePopup} from "../../../store/user-slice";
import buttonBG from "../../../assets/img/loginBtn.png";
import popupImgMob from "../../../assets/img/mobilePopups/mobTransactionResult.png";

const FailedPopup = () => {
    const {setPopup} = usePopup();
    const dispatch = useAppDispatch();

    const onBack = () => {
        setTimeout(() => {
            setPopup('stop').then(() => {dispatch(setActivePopup(null))})
        }, 50)
    }

    return (
        <div style={window.innerWidth > 767 ? {backgroundImage: `url(${failedPopup})`} : {backgroundImage: `url(${popupImgMob})`}}
             className={styles.failed}>
            <Closer />
            {window.innerWidth < 767 && (
                <h4>Transaction</h4>
            )}
            {window.innerWidth < 767 && (
                <p className={styles.text}>Transaction failed</p>
            )}
            <div style={{backgroundImage: `url(${buttonBG})`}} className={styles.buttonWrapper}>
                <button onClick={onBack}>
                    <span>Back</span>
                </button>
            </div>
        </div>
    )
}

export default FailedPopup;