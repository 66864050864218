import React, {useEffect, useState} from "react";
import styles from './UserBalance.module.scss';
import {useSelector} from "react-redux";
import {getCoinsBalance, getWithdrawn} from "../../../store/selectors";
import {getCourse} from "../../../api/ethereum";

const UserBalance = () => {
    const [output, setOutput] = useState(0)
    const [collection, setCollection] = useState(0)
    const {ethers} = require("ethers");
    const courseCoin = 200000;
    const withdrawn = useSelector(getWithdrawn);
    const coinsBalance = useSelector(getCoinsBalance);

    useEffect(() => {
        getCourse().then(response => setOutput(ethers.formatEther(withdrawn) * response.price))
    }, [withdrawn])

    useEffect(() => {
        const exchange = coinsBalance / courseCoin
        getCourse().then(response => setCollection(exchange * response.price))
    }, [coinsBalance])

    return (
        <>
            <div className={styles.userBalance}>
                <div>
                    <p>Money withdrawn</p>
                    <span>{output.toFixed(2)} <span className={styles.dollar}>$</span></span>
                </div>
                <div>
                    <p>Collection of coins</p>
                    <span>{collection.toFixed(2)} <span className={styles.dollar}>$</span></span>
                </div>
            </div>

        </>

    )
}

export default UserBalance;