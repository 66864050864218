import React, {useState} from "react";
import styles from './FightPopup.module.scss'
import btnLittle from "../../../assets/img/btnLittle.png";
import {getContract, getCookies, getProvider, sendCreatArena} from "../../../api/ethereum";
import {
    setActivePopup,
    setCoinsBalance,
    setCookiesBalance,
    setMyInvested,
    setMyWithdrawn
} from "../../../store/user-slice";
import {ethers} from "ethers";
import {useSelector} from "react-redux";
import {getWalletAddress} from "../../../store/selectors";
import {useAppDispatch} from "../../../hooks/redux";
import {usePopup} from "../../../hooks/useGlobalFunction";

const Level = ({level, index}) => {
    const walletAddress = useSelector(getWalletAddress);
    const dispatch = useAppDispatch();
    const {setPopup} = usePopup();
    const [disabled, setDisabled] = useState(false);

    const onSubmit = () => {
        getProvider().then(provider => {
            getContract(provider).then(contract => {
                provider.getSigner().then((signer) => {
                    setDisabled(true);
                    setPopup('start').then(() => dispatch(setActivePopup('transaction')));
                    sendCreatArena(contract, signer, index)
                        .then((result) => {
                            if (result === false) {
                                setPopup('start').then(() => dispatch(setActivePopup('failed')));
                                setDisabled(false);
                            } else {
                                result.wait().then((response) => {
                                    if (response && response.status === 1) {
                                        getCookies(contract, walletAddress).then(r => {
                                            const cookies = r[0].toString();
                                            const coins = r[1].toString();
                                            const invested = r[8].toString();
                                            const withdrawn = r[9].toString();
                                            dispatch(setCookiesBalance(cookies));
                                            dispatch(setCoinsBalance(coins));
                                            dispatch(setMyInvested(invested));
                                            dispatch(setMyWithdrawn(withdrawn));
                                            setPopup('stop').then(() => dispatch(setActivePopup(null)));
                                            setPopup('start').then(() => dispatch(setActivePopup('success')));
                                        });
                                    } else {
                                        setPopup('start').then(() => dispatch(setActivePopup('failed')));
                                    }
                                });
                                setDisabled(false);
                            }
                        });
                });
            });
        })
    }

    return (
        <div className={styles.level}>
            <div className={styles.level__box}>
                <p>{level.level}</p>
                <div className={styles.level__actionsWrapper}>
                    <div className={styles.level__actions} style={{backgroundImage: `url(${btnLittle})`}}>
                        <button disabled={disabled} onClick={onSubmit}>
                            <span>{level.cost}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Level;