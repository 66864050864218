import React from "react";
import feedPopup from "../../../assets/img/popups/feedPopup.png";
import feedPopupMob from "../../../assets/img/mobilePopups/feedPopupMob.png";
import styles from './FeedPopup.module.scss';
import Closer from "../../Common/Closer/Closer";
import AnimalList from "./AnimalList";

const FeedPopup = () => {
    return (
        <div style={window.innerWidth > 767 ? {backgroundImage: `url(${feedPopup})`} : {backgroundImage: `url(${feedPopupMob})`}}
             className={styles.feed}>
            {window.innerWidth < 767 && (
                <h2 className={styles.title}>Feed the animal</h2>
            )}
            <Closer />
            <AnimalList />
        </div>
    )
}

export default FeedPopup;