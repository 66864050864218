import React from "react";
import styles from './PartnersPopup.module.scss';
import coin from '../../../assets/img/coin.png';
import cookies from '../../../assets/img/cookies.png';
import {useSelector} from "react-redux";
import {getAffiliate} from "../../../store/selectors";

const PlayerStats = () => {
    const affiliateStats = useSelector(getAffiliate);

    return (
        <div className={styles.playerWrapper}>
            <h3>Your Affiliate Statistics</h3>
            <div className={styles.statsBox}>
                <p className={styles.statsBox__head}>In-game currency</p>
                <p className={styles.statsBox__headValue}>Profit</p>
            </div>
            <div className={styles.statsBox}>
                <p className={styles.statsBox__text}>Players</p>
                <div className={styles.statsBox__value}>
                    <span>{affiliateStats.players}</span>
                </div>
            </div>
            <div className={styles.statsBox}>
                <p className={styles.statsBox__text}>Cookies</p>
                <div className={styles.statsBox__value}>
                    <span>{affiliateStats.cookies}</span><img src={cookies} alt=""/>
                </div>
            </div>
            <div className={styles.statsBox}>
                <p className={styles.statsBox__text}>Coin</p>
                <div className={styles.statsBox__value}>
                    <span>{affiliateStats.coins}</span><img src={coin} alt=""/>
                </div>
            </div>
        </div>
    )
}

export default PlayerStats;