import React from "react";
import styles from './AuditPopup.module.scss';
import Closer from "../../Common/Closer/Closer";
import button from "../../../assets/img/btnInput.png";
import auditPdf from '../../../pdf/BeFundAudit.pdf'
import popupImg from '../../../assets/img/popups/Audit.png';
import popupImgMob from '../../../assets/img/mobilePopups/AuditMob.png';

const AuditPopup = () => {
    return (
        <div
            style={window.innerWidth > 767 ? {backgroundImage: `url(${popupImg})`} : {backgroundImage: `url(${popupImgMob})`}}
            className={styles.auditPopup}>
            <Closer/>
            {window.innerWidth < 767 && (
                <h4>Audit</h4>
            )}
            <div className={styles.auditPopup__actions}>
                <div className={styles.buttonWrapper} style={{backgroundImage: `url(${button})`}}>
                    <a href={auditPdf} target='_blank'><span>Audit #1</span></a>
                </div>
                <div className={styles.buttonWrapper} style={{backgroundImage: `url(${button})`}}>
                    <a href='https://github.com/solidproof/projects/tree/main/2024/Magic%20Animal' target='_blank'><span>Audit #2</span></a>
                </div>
            </div>
        </div>
    )
}

export default AuditPopup;